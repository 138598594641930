import {BreakPoints, IDetectPhone} from "shared-types/index";

const NS = 'LocationService';

export default class LocationService {

//isSSL ?
    static isSSH(url: string = window.location.href): boolean {
        return url.indexOf('https') === 0;
    }

    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
    static isIOS(): boolean {
      return (/iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
        !(window as any).MSStream;
    }

    static isAndroid(): boolean {
      return navigator.userAgent.toLowerCase().indexOf("android") > -1;
    }

    /**
     * Detects if user's brower is Safari. It uses the vendor metadata filtering out when the isChromeIPAD
     * returns true. Chrome Browsers on IPAD also have vendor metadata set as "Apple"
     */
    static isSafari(): boolean {
        return (navigator.vendor.search("Apple") >= 0 && !LocationService.isChromeIPAD());
    }

    // https://chromium.googlesource.com/chromium/src.git/+/HEAD/docs/ios/user_agent.md
    /**
     * Detects if the user's browser is Chrome in a Ipad device. The only way to detect it according to the link above
     * is verify the presence of the string "CriOS" on the userAgent property
     */
    static isChromeIPAD(): boolean {
        return (navigator.userAgent.search("CriOS") >= 0);
    }

    /**
     * Detects if the outer iframe container (native HTML page) is using a phone, by
     * checking the window's outerWidth. Also checks for iPhone if you need iPhone specifically,
     * which works on XCode iPhone simulator as well.
     */
    static detectPhone(): IDetectPhone {
        return {
            isPhone: window.outerWidth <= BreakPoints.STACKED_BP,
            isIPhone: (/iPhone/.test(navigator.platform)),
        };
    }


    /**
     * RFC4122 compliant GUID generator for JS that is compact and "good enough" for what we want.
     * Should only get called once per session/app load.
     * Used for better SEQ logging.
     */
    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    /**
     * Accepts a url and removes either token param and/or edit query string
     */
    static stripCurrentUrl(url: string, removeToken: boolean, removeEdit: boolean): string {

        if (removeToken) {
            const splitUrl: string[] = url.split('&token=');
            url = splitUrl[0];

            if (splitUrl[1] && splitUrl[1].indexOf('&') !== -1) {
                const trailingParams = splitUrl[1].split('&')[1];
                url += '&' + trailingParams;
            }
        }

        if (removeEdit) {
            url = url.replace('edit?', '?');
        }

        return url;
    }

    static isLocalHost(config?: {origin: string, port: string}): boolean {
      const localHostTypes = ['localhost', '0.0.0.0', '127.0.0.1'];
      if (config) {
        const {port, origin} = config;
        const protocol = window.location.protocol;
        return localHostTypes.some(type => (origin === `${protocol}//${type}:${port}`))
      } else {
        const hostname = window.location.hostname;
        return localHostTypes.some(type => hostname === type);
      }
    }

}
